html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
  // border: solid 20px red !important;
}

body .capitalize-text{
  text-transform: capitalize;
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder { /* Recent browsers */
  text-transform: none;
}
// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

.modal-historial{
  width: '1200px' !important;
}

.tooltip-soft{
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}


.tooltip-soft .tooltiptext {
  visibility: hidden;
  width:max-content;
  // min-width: 150px;
  background-color: #fff;
  box-shadow: 5px 5px 6px -3px rgba(0,0,0,0.19);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: 50%;
  margin-left: -60px;
  // margin-top: 60px;
}

.tooltip-soft:hover .tooltiptext {
  visibility: visible;
}